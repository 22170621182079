import gql from "graphql-tag";

export const AUTHORIZATION = gql`
  mutation auth($login: String!, $password: String!, $token: String!) {
    auth(login: $login, password: $password, token:$token) {
      sid
      me {
        isn
      }
    }
  }
`;
export const REFRESH_SID = gql`
  mutation refreshSid($sid: String!) {
    refreshSid(sid: $sid) {
      sid
      me {
        isn
      }
    }
  }
`;
export const RECOVERY = gql`
  mutation recovery($login: String!, $contact: String!) {
    recovery(login: $login, contact: $contact)
  }
`;
export const USER_CHANGE_INFO = gql`
  mutation userChangeInfo($email: String, $phone: String) {
    userChangeInfo(email: $email, phone: $phone) {
      email
      phone
    }
  }
`;
export const UPLOAD_BORDEREAUX = gql`
  mutation uploadRows($docClassIsn: ID!, $doc: Upload!) {
    uploadRows(docClassIsn: $docClassIsn, doc: $doc)
  }
`;

export const DELETE_ROWS = gql`
  mutation deleteRow($docClassIsn: ID!, $docRowsIsn: [ID!]) {
    deleteRow(docClassIsn: $docClassIsn, docRowsIsn: $docRowsIsn)
  }
`;

export const DELETE_BORDEREAUX = gql`
  mutation deleteRow($docClassIsn: ID!) {
    deleteRow(docClassIsn: $docClassIsn)
  }
`;

export const CREATE_ROWS = gql`
  mutation createRow($docClassIsn: ID!, $data: [CreateRowInput!]!) {
    createRow(docClassIsn: $docClassIsn, data: $data)
  }
`;
/**
 */
export const UPDATE_ROWS = gql`
  mutation updateRow(
    $docClassIsn: ID!
    $argIsn: ID!
    $rowIsn: ID!
    $account: String
    $air: Boolean
    $altDriver: String
    $altEmptyFields: String
    $altFormOfTransportAuto: String
    $altFormOfTransportRailway: String
    $altGeograeTransport: String
    $altLimitOfTheSumInsured: String
    $altLoad: String
    $altModeOfTransportAir: String
    $altRate: String
    $altSecurity: String
    $altTheTimeOfApplication: String
    $altTransportationGeography: String
    $altViewTransportWater: String
    $auto: Boolean
    $certificate: String
    $currency: String
    $currencyType: String
    $dateOfCertificate: Date
    $dateOfCourse: Date
    $dateSent: Date
    $destination: String
    $insurancePremiumInRubles: String
    $insurancePremiumInTheCurrency: String
    $nameOfGoods: String
    $numberAndDateApr: String
    $numberAndDateOfShippingDocuments: String
    $numberOfSeats: String
    $railway: Boolean
    $reviewDateCarrier: Date
    $reviewForCarriage: String
    $routeDetails: String
    $shipmentNumber: String
    $statusAction: String
    $tariffRate: String
    $theClaimNumber: String
    $theInvoiceNumber: String
    $theNatureOfThePackagingOfGoods: String
    $theNumberOfTheDeclaration: String
    $theNumberOfTheVehicle: String
    $thePointOfDeparture: String
    $theSumInsured: String
    $theWeightOfTheLoad: String
    $water: Boolean
  ) {
    updateRow(
      docClassIsn: $docClassIsn
      argIsn: $argIsn
      rowIsn: $rowIsn
      account: $account
      air: $air
      altDriver: $altDriver
      altEmptyFields: $altEmptyFields
      altFormOfTransportAuto: $altFormOfTransportAuto
      altFormOfTransportRailway: $altFormOfTransportRailway
      altGeograeTransport: $altGeograeTransport
      altLimitOfTheSumInsured: $altLimitOfTheSumInsured
      altLoad: $altLoad
      altModeOfTransportAir: $altModeOfTransportAir
      altRate: $altRate
      altSecurity: $altSecurity
      altTheTimeOfApplication: $altTheTimeOfApplication
      altTransportationGeography: $altTransportationGeography
      altViewTransportWater: $altViewTransportWater
      auto: $auto
      certificate: $certificate
      currency: $currency
      currencyType: $currencyType
      dateOfCertificate: $dateOfCertificate
      dateOfCourse: $dateOfCourse
      dateSent: $dateSent
      destination: $destination
      insurancePremiumInRubles: $insurancePremiumInRubles
      insurancePremiumInTheCurrency: $insurancePremiumInTheCurrency
      nameOfGoods: $nameOfGoods
      numberAndDateApr: $numberAndDateApr
      numberAndDateOfShippingDocuments: $numberAndDateOfShippingDocuments
      numberOfSeats: $numberOfSeats
      railway: $railway
      reviewDateCarrier: $reviewDateCarrier
      reviewForCarriage: $reviewForCarriage
      routeDetails: $routeDetails
      shipmentNumber: $shipmentNumber
      statusAction: $statusAction
      tariffRate: $tariffRate
      theClaimNumber: $theClaimNumber
      theInvoiceNumber: $theInvoiceNumber
      theNatureOfThePackagingOfGoods: $theNatureOfThePackagingOfGoods
      theNumberOfTheDeclaration: $theNumberOfTheDeclaration
      theNumberOfTheVehicle: $theNumberOfTheVehicle
      thePointOfDeparture: $thePointOfDeparture
      theSumInsured: $theSumInsured
      theWeightOfTheLoad: $theWeightOfTheLoad
      water: $water
    )
  }
`;

export const SEND_BORDEREAUX_TO_TRANSPORT_LOG = gql`
  mutation sendBordereauxToTransportLog(
    $docClassIsn: [ID!]!
    $transportLogId: ID!
  ) {
    sendBordereauxToTransportLog(
      docClassIsn: $docClassIsn
      transportLogId: $transportLogId
    )
  }
`;

export const CREATE_CLAIM = gql`
  mutation createClaim($data: CreateClaimInput!) {
    createClaim(data: $data) {
      shippingNumber
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation createInvoice($docClassIsn: ID!, $isn: [ID!]!) {
    createInvoice(docClassIsn: $docClassIsn, isn: $isn)
  }
`;

export const GET_CERTIFICATE = gql`
  mutation getCertificate($docClassIsn: ID!, $isn: [ID!]!, $certType: String) {
    getCertificate(docClassIsn: $docClassIsn, isn: $isn, certType: $certType)
  }
`;
export const UPDATE_CLAIM = gql`
  mutation updateClaimMutation($data: UpdateClaimInput!) {
    updateClaimMutation(data: $data)
  }
`;
