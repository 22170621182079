import gql from "graphql-tag";

export const GET_BORDEREAUX = gql`
  query bordereaux(
    $docClassIsn: ID!
    $docClassIsnSingleShip: ID!
    $filter: String
    $statusAction: [StatusAction]
  ) {
    bordereaux(
      docClassIsn: $docClassIsn
      filter: $filter
      statusAction: $statusAction
    ) {
      isn
      theClaimNumber {
        description
        value
        hidden
      }
      dateSent {
        description
        value
        hidden
      }
      thePointOfDeparture {
        description
        isn
        full_name
        hidden
      }
      destination {
        description
        isn
        full_name
        hidden
      }
      nameOfGoods {
        description
        value
        hidden
      }
      theNumberOfTheVehicle {
        description
        value
        hidden
      }
      numberAndDateOfShippingDocuments {
        description
        value
        hidden
      }
      theSumInsured {
        description
        value
        hidden
      }
      insurancePremiumInTheCurrency {
        description
        value
        hidden
      }
      statusAction {
        description
        value
        hidden
      }
      currencyType {
        description
        value
        hidden
      }

      altDriver {
        value
      }
      altEmptyFields {
        value
      }
      altFormOfTransportAuto {
        value
      }
      altFormOfTransportRailway {
        value
      }
      altGeograeTransport {
        value
      }
      altLimitOfTheSumInsured {
        value
      }
      altLoad {
        value
      }
      altModeOfTransportAir {
        value
      }
      altRate {
        value
      }
      altSecurity {
        value
      }
      altTheTimeOfApplication {
        value
      }
      altTransportationGeography {
        value
      }
      altViewTransportWater {
        value
      }
    }
    singleShip: bordereaux(
      docClassIsn: $docClassIsnSingleShip
      filter: $filter
      statusAction: $statusAction
    ) {
      isn
      theClaimNumber {
        description
        value
        hidden
      }
      dateSent {
        description
        value
        hidden
      }
      thePointOfDeparture {
        description
        isn
        full_name
        hidden
      }
      destination {
        description
        isn
        full_name
        hidden
      }
      nameOfGoods {
        description
        value
        hidden
      }
      theNumberOfTheVehicle {
        description
        value
        hidden
      }
      numberAndDateOfShippingDocuments {
        description
        value
        hidden
      }
      theSumInsured {
        description
        value
        hidden
      }
      insurancePremiumInTheCurrency {
        description
        value
        hidden
      }
      statusAction {
        description
        value
        hidden
      }
      currencyType {
        description
        value
        hidden
      }

      altDriver {
        value
      }
      altEmptyFields {
        value
      }
      altFormOfTransportAuto {
        value
      }
      altFormOfTransportRailway {
        value
      }
      altGeograeTransport {
        value
      }
      altLimitOfTheSumInsured {
        value
      }
      altLoad {
        value
      }
      altModeOfTransportAir {
        value
      }
      altRate {
        value
      }
      altSecurity {
        value
      }
      altTheTimeOfApplication {
        value
      }
      altTransportationGeography {
        value
      }
      altViewTransportWater {
        value
      }
    }
  }
`;
export const GET_DOCS = gql`
  query docs($dateBefore: Date, $dateAfter: Date) {
    docs(dateBefore: $dateBefore, dateAfter: $dateAfter) {
      isn
      description
      docs {
        isn
        date
        subJisn
        argIsn
      }
    }
    agreements {
      isn
      number
      docDate
      firstName
      surName
      middleName
      email
    }
  }
`;
export const USER = gql`
  query me {
    me {
      ...User
    }
  }
  fragment User on User {
    isn
    canEditSubjects
    daysToChangePwd
    defaultCityIsn
    defaultCityName
    deptIsn
    fullName
    isPartner
    isPartnerManager
    isSalePointCurator
    psbisn
    partnerDeptIsn
    partnerEmplFullName
    partnerEmplIsn
    partnerEmplShortName
    partnerNodeIsn
    partnerNodeSubjIsn
    partnerNodeSubjName
    shortName
    upperDeptIsn
    upperDeptName
    userClassIsn
    userRoles
    userSessionCount
    agreementDetails
  }
`;
export const GET_AGREEMENTS = gql`
  query {
    agreements {
      isn
      number
      firstName
      surName
      middleName
      phone
      email
      attaches {
        isn
        fileName
        url
      }
    }
  }
`;

export const GET_INVOICES = gql`
  query {
    invoices {
      isn
      id
      sum
      cur
      docDate
      datePay
      status
      webAttaches
      sum_cur
    }
  }
`;

export const GET_FILES = gql`
  query files($refIsn: String!) {
    files(refIsn: $refIsn) {
      url
      row
    }
  }
`;

export const GET_TRANSPORT_LOG = gql`
  query bordereaux($docClassIsn: ID!, $filter: String) {
    bordereaux(
      docClassIsn: $docClassIsn
      filter: $filter
      restrictedFieldsType: journal_of_transportation
    ) {
      isn
      docId {
        description
        value
        hidden
      }
      dateSent {
        description
        value
        hidden
      }
      thePointOfDeparture {
        description
        isn
        full_name
        hidden
      }
      destination {
        description
        isn
        full_name
        hidden
      }
      nameOfGoods {
        description
        value
        hidden
      }
      theNumberOfTheVehicle {
        description
        value
        hidden
      }
      numberAndDateOfShippingDocuments {
        description
        value
        hidden
      }
      theSumInsured {
        description
        value
        hidden
      }
      insurancePremiumInTheCurrency {
        description
        value
        hidden
      }
      insurancePremiumInRubles {
        description
        value
        hidden
      }
      certificate {
        description
        value
        hidden
      }
      account {
        description
        value
        hidden
      }
      currencyType {
        description
        value
        hidden
      }
    }
  }
`;
export const GET_SHIPPING_BY_ISN = gql`
  query bordereaux($docClassIsn: ID!, $isn: ID) {
    bordereaux(
      docClassIsn: $docClassIsn
      isn: $isn
      restrictedFieldsType: journal_of_transportation
    ) {
      isn
      account {
        description
        value
        hidden
      }
      air {
        description
        value
        hidden
      }
      docId {
        value
      }
      altDriver {
        description
        value
        hidden
      }
      altEmptyFields {
        description
        value
        hidden
      }
      altFormOfTransportAuto {
        description
        value
        hidden
      }
      altFormOfTransportRailway {
        description
        value
        hidden
      }
      altGeograeTransport {
        description
        value
        hidden
      }
      altLimitOfTheSumInsured {
        description
        value
        hidden
      }
      altLoad {
        description
        value
        hidden
      }
      altModeOfTransportAir {
        description
        value
        hidden
      }
      altRate {
        description
        value
        hidden
      }
      altSecurity {
        description
        value
        hidden
      }
      altTheTimeOfApplication {
        description
        value
        hidden
      }
      altTransportationGeography {
        description
        value
        hidden
      }
      altViewTransportWater {
        description
        value
        hidden
      }
      auto {
        description
        value
        hidden
      }
      certificate {
        description
        value
        hidden
      }
      currency {
        description
        value
        hidden
      }
      currencyType {
        description
        value
        hidden
      }
      dateOfCertificate {
        description
        value
        hidden
      }
      dateOfCourse {
        description
        value
        hidden
      }
      dateSent {
        description
        value
        hidden
      }
      destination {
        description
        isn
        full_name
        hidden
      }
      insurancePremiumInRubles {
        description
        value
        hidden
      }
      insurancePremiumInTheCurrency {
        description
        value
        hidden
      }
      nameOfGoods {
        description
        value
        hidden
      }
      numberAndDateApr {
        description
        value
        hidden
      }
      numberAndDateOfShippingDocuments {
        description
        value
        hidden
      }
      numberOfSeats {
        description
        value
        hidden
      }
      railway {
        description
        value
        hidden
      }
      reviewDateCarrier {
        description
        value
        hidden
      }
      reviewForCarriage {
        description
        value
        hidden
      }
      routeDetails {
        description
        value
        hidden
      }
      shipmentNumber {
        description
        value
        hidden
      }
      statusAction {
        description
        value
        hidden
      }
      tariffRate {
        description
        value
        hidden
      }
      theClaimNumber {
        description
        value
        hidden
      }
      theInvoiceNumber {
        description
        value
        hidden
      }
      theNatureOfThePackagingOfGoods {
        description
        value
        hidden
      }
      theNumberOfTheDeclaration {
        description
        value
        hidden
      }
      theNumberOfTheVehicle {
        description
        value
        hidden
      }
      thePointOfDeparture {
        description
        isn
        full_name
        hidden
      }
      theSumInsured {
        description
        value
        hidden
      }
      theWeightOfTheLoad {
        description
        value
        hidden
      }
      water {
        description
        value
        hidden
      }
    }
  }
`;

export const GET_RESTRICTED_FIELDS = gql`
  query restrictedFields(
    $docClassIsn: ID!
    $restrictedFieldsType: RestrictedFieldsType = adjustment_of_borders
  ) {
    restrictedFields(
      docClassIsn: $docClassIsn
      restrictedFieldsType: $restrictedFieldsType
    )
  }
`;

export const GET_CLAIMS = gql`
  query claims($argIsn: String!) {
    claims(argIsn: $argIsn) {
      isn
      claimSERVREQID
      id
      sum
      remark
      date
      shippingNumber
      shippingLabel
      city
      event
      damage
      country
      status
    }
  }
`;

export const GET_CLAIM = gql`
  query claims($argIsn: String!, $isn: ID) {
    claims(argIsn: $argIsn, isn: $isn) {
      isn
      claimSERVREQID
      id
      docIsn
      sum
      cur
      remark
      date
      shippingNumber
      shippingLabel
      city
      event
      damage
      country
      status
      attaches {
        name
        isn
        url
      }
    }
  }
`;
export const GET_API_DATA = gql`
  query singleton($name: String!) {
    singleton(name: $name)
  }
`;
export const GET_PUB_KEY = gql`
  query pubKey {
    pubKey
  }
`;
